import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "video" */ '../views/HomeView.vue'),
    },

    {
        path: '/books',
        name: 'books',
        component: () => import(/* webpackChunkName: "videos" */ '../views/books/BooksHomeView.vue'),
    },
    {
        path: '/books/volume/:volume_id',
        name: 'volumeBooks',
        component: () => import(/* webpackChunkName: "video" */ '../views/books/VolumeBooksView.vue'),
        props: true
    },
    {
        path: '/books/book/:book_id',
        name: 'BookCover',
        component: () => import(/* webpackChunkName: "video" */ '../views/books/BookCoverView.vue'),
        props: true
    },
    {
        path: '/books/video/:book_id',
        name: 'BookClassVideo',
        component: () => import(/* webpackChunkName: "video" */ '../views/books/BookClassVideoView.vue'),
        props: true
    },
    {
        path: '/books/book/l/:book_id',
        name: 'BookLandscape',
        component: () => import(/* webpackChunkName: "video" */ '../views/books/BookLandscapeView.vue'),
        props: true
    },
    {
        path: '/books/book/p/:book_id',
        name: 'BookPortrait',
        component: () => import(/* webpackChunkName: "video" */ '../views/books/BookPortraitView.vue'),
        props: true
    },
    {
        path: '/settings',
        name: 'BookSettings',
        component: () => import(/* webpackChunkName: "video" */ '../views/user/SettingsView.vue'),
        props: true
    },
    {
        path: '/words/test/book/:book_id',
        name: 'wordTestControl',
        component: () => import(/* webpackChunkName: "video" */ '../views/books/PageTestControlView.vue'),
        props: true
    },
    {
        path: '/words/test/:category',
        name: 'userWordsTest',
        component: () => import(/* webpackChunkName: "video" */ '../views/words/WordTestControlView.vue'),
        props: true
    },
    {
        path: '/words',
        name: 'userWords',
        component: () => import(/* webpackChunkName: "video" */ '../views/words/WordsHomeView.vue'),
        props: true
    },
    {
        path: '/words/:category/',
        name: 'userVocabulary',
        component: () => import(/* webpackChunkName: "video" */ '../views/words/UserVocabularyView.vue'),
        props: true
    },
    {
        path: '/words/cards/category/:category',
        name: 'categoryCards',
        component: () => import(/* webpackChunkName: "video" */ '../views/words/CategoryCardsView.vue'),
        props: true
    },
    {
        path: '/words/cards/test/:type/:category/:sub_category',
        name: 'categoryCardsTest',
        component: () => import(/* webpackChunkName: "video" */ '../views/words/CardsTestControlView.vue'),
        props: true
    },
    // {
    //     path: '/admin/cards/category/:category',
    //     name: 'adminCards',
    //     component: () => import(/* webpackChunkName: "video" */ '../views/admin/AdminCategoryCardsView.vue'),
    //     props: true
    // },
    // {
    //     path: '/admin/cards/group',
    //     name: 'adminGroupCards',
    //     component: () => import(/* webpackChunkName: "video" */ '../views/admin/AdminGroupCategoryCardsView.vue'),
    // },
    {
        path: '/user/:user_id/word_favorites',
        name: 'wordFavorites',
        component: () => import(/* webpackChunkName: "video" */ '../views/words/FavoritesView.vue'),
        props: true
    },
    {
        path: '/videos',
        name: 'videosGallery',
        component: () => import(/* webpackChunkName: "video" */ '../views/videos/VideosHomeView.vue'),
    },
    {
        path: '/v/:content_slug',
        name: 'video',
        component: () => import(/* webpackChunkName: "video" */ '../views/videos/VideoContentView.vue'),
        props: true
    },
    {
        path: '/v/:content_slug/:season_slug',
        name: 'season',
        component: () => import(/* webpackChunkName: "video" */ '../views/videos/SeasonView.vue'),
        props: true
    },
    {
        path: '/v/:content_slug/:season_slug/:episode_slug/',
        name: 'video_play',
        // component: () => import(/* webpackChunkName: "video" */ '../views/VideoPlayView.vue'),
        component: () => import(/* webpackChunkName: "video" */ '../views/videos/VideoPlayer.vue'),
        props: true,
    },

    {
        path: '/wx/login',
        name: 'WeChatAuth',
        component: () => import(/* webpackChunkName: "video" */ '../views/user/WeChatAuthView.vue'),
    },
    {
        path: '/wx/callback',
        name: 'WeChatAuthCallback',
        component: () => import(/* webpackChunkName: "video" */ '../views/user/WeChatAuthCallbackView.vue'),
    },
    {
        path: '/wx/complete',
        name: 'WeChatAuthComplete',
        component: () => import(/* webpackChunkName: "video" */ '../views/user/WeChatAuthCompleteView.vue'),
    },
    {
        path: '/wx/bp',
        name: 'bp',
        component: () => import(/* webpackChunkName: "video" */ '../views/user/BindPhoneView.vue'),
    },
    {
        path: '/wx/bp/complete',
        name: 'bpComplete',
        component: () => import(/* webpackChunkName: "video" */ '../views/user/BindPhoneCompleteView.vue'),
    },
    {
        path: '/my',
        name: 'my',
        component: () => import(/* webpackChunkName: "video" */ '../views/user/MyView.vue'),
    },
    // {
    //     path: '/register',
    //     name: 'register',
    //     component: () => import(/* webpackChunkName: "video" */ '../views/admin/RegisterView.vue'),
    // },
    // {
    //     path: '/wx/admin/login',
    //     name: 'login',
    //     component: () => import(/* webpackChunkName: "video" */ '../views/LoginView.vue'),
    // },
    // {
    //     path: '/change-password',
    //     name: 'change-password',
    //     component: () => import(/* webpackChunkName: "video" */ '../views/user/ChangePasswordView.vue'),
    // },
    {
        path: '/coming-soon/:tip',
        name: 'coming-soon',
        component: () => import(/* webpackChunkName: "video" */ '../views/ComingSoonView.vue'),
        props: true,
    },
    // {
    //     path: '/admin/video',
    //     name: 'admin-video',
    //     component: () => import(/* webpackChunkName: "video" */ '../views/admin/VideoContentView.vue'),
    // },
    // {
    //     path: '/admin/word/book/:book_id',
    //     name: 'wordBookPage',
    //     component: () => import(/* webpackChunkName: "video" */ '../views/admin/InsertPagesIntoWordView.vue'),
    // },
    // {
    //     path: '/admin/addTestWord',
    //     name: 'admin-word',
    //     component: () => import(/* webpackChunkName: "video" */ '../views/admin/WordAndTest.vue'),
    // },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

function checkModelPermission(to, next) {
    if (to.path.startsWith('/words')) {
        // 是否有权限访问单词页面
        const permissionsStr = localStorage.getItem('permissions');
        const permissions = JSON.parse(permissionsStr || '{}'); // 使用'{}'作为默认值以避免解析错误
        const wordExpiredAt = permissions['word'];
        // 比较当前日期和过期日期
        const currentDate = new Date();
        const expiredDate = wordExpiredAt ? new Date(wordExpiredAt) : new Date(0); // 使用Unix纪元开始时间作为默认值
        if (currentDate > expiredDate) {
            next('/coming-soon/单词功能');
        } else {
            next();
        }
    } else if (to.path.startsWith('/books')) {
        // 是否有权限访问绘本页面
        const permissionsStr = localStorage.getItem('permissions');
        const permissions = JSON.parse(permissionsStr || '{}'); // 使用'{}'作为默认值以避免解析错误
        const bookExpiredAt = permissions['book'];
        // 比较当前日期和过期日期
        const currentDate = new Date();
        const expiredDate = bookExpiredAt ? new Date(bookExpiredAt) : new Date(0); // 使用Unix纪元开始时间作为默认值
        if (currentDate > expiredDate) {
            next('/coming-soon/绘本功能');
        } else {
            next();
        }
    } else if (to.path.startsWith('/videos')) {
        // 是否有权限访问视频页面
        const permissionsStr = localStorage.getItem('permissions');
        const permissions = JSON.parse(permissionsStr || '{}'); // 使用'{}'作为默认值以避免解析错误
        const videoExpiredAt = permissions['video'];
        // 比较当前日期和过期日期
        const currentDate = new Date();
        const expiredDate = videoExpiredAt ? new Date(videoExpiredAt) : new Date(0); // 使用Unix纪元开始时间作为默认值
        if (currentDate > expiredDate) {
            next('/coming-soon/视频功能');
        } else {
            next();
        }
    } else {
        next();
    }
}

function checkHomePermission(to, next) {
    const token = localStorage.getItem('lc_jwt');
    const time = localStorage.getItem('time');  // 格式：2024/3/5 12:41:26
    let tokenLength = token ? token.length : 0;
    // 如果当前时间与上次登录时间相差超过14天，则刷新token
    let currentTime = new Date(new Date().toLocaleString());
    let lastLoginTime = new Date(time);
    let diff = currentTime - lastLoginTime;
    // 14天后需要重新登录
    if (diff > 1209600000) {
        localStorage.removeItem('lc_jwt');
        localStorage.removeItem('time');
        next('/wx/login');
    } else {
        if (tokenLength < 64) {
            next('/wx/login');
        }
        next();
    }
}

router.beforeEach((to, from, next) => {
        // 获取token，例如从localStorage
        const token = localStorage.getItem('lc_jwt');
        let tokenLength = token ? token.length : 0;

        // 检查 token
        if (process.env.VUE_APP_PRODUCTION) {
            if (to.path === '/') {
                checkHomePermission(to, next);
            } else {
                if (to.path.startsWith('/wx')) {
                    next();
                } else if (tokenLength < 64) {
                    next('/wx/login');
                } else {
                    checkModelPermission(to, next);
                }
            }
        } else if (process.env.VUE_APP_DEVELOPMENT) {
            const time = localStorage.getItem('time');  // 格式：2024/3/5 12:41:26
            // next();
            if (to.path === '/') {
                // 如果当前时间与上次登录时间相差超过12小时，则刷新token
                let currentTime = new Date(new Date().toLocaleString());
                let lastLoginTime = new Date(time);
                let diff = currentTime - lastLoginTime;
                console.log("currentTime: ", currentTime);
                console.log("lastLoginTime: ", lastLoginTime);
                console.log("diff: ", diff / 1000);

                if (diff > 43200000) {
                    localStorage.removeItem('lc_jwt');
                    localStorage.removeItem('time');
                    next('/login');
                } else {
                    if (tokenLength < 64) {
                        next('/login');
                    }
                    next();
                }
            } else {
                if (to.path.startsWith('/login')) {
                    next();
                } else if (tokenLength < 64) {
                    next('/login');
                } else {
                    checkModelPermission(to, next);
                }
            }
        } else {
            console.log("运行模式错误！")
        }
    }
);

export default router