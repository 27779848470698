// store.js
import Vuex from 'vuex'  // 导入 Vuex

export default new Vuex.Store({
    state: {
        isLoggedIn: !!localStorage.getItem('isLoggedIn'),
        username: localStorage.getItem('username'),
        userId: localStorage.getItem('userId'),
        showChineseSubtitle: localStorage.getItem('showChineseSubtitle') !== 'false',
        showEnglishSubtitle: localStorage.getItem('showEnglishSubtitle') !== 'false',
    },
    mutations: {
        setUser(state, { isLoggedIn, username, userId }) {
            state.isLoggedIn = isLoggedIn
            state.username = username
            state.userId = userId

            if (isLoggedIn) {
                localStorage.setItem('isLoggedIn', 'true')
                localStorage.setItem('username', username)
                localStorage.setItem('userId', userId)
            } else {
                localStorage.removeItem('isLoggedIn')
                localStorage.removeItem('username')
                localStorage.removeItem('userId')
            }
        },
        setShowChineseSubtitle(state, value) {
            state.showChineseSubtitle = value;
            localStorage.setItem('showChineseSubtitle', value);
        },
        setShowEnglishSubtitle(state, value) {
            state.showEnglishSubtitle = value;
            localStorage.setItem('showEnglishSubtitle', value);
        },
    },
})