import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import './assets/styles/index.css';
import './assets/styles/quicksand.css';
import store from './store/store'
import { historyManager } from './utils/historyManager.js'

const app = createApp(App);

// 全局禁止双击操作
document.addEventListener('dblclick', (event) => {
    event.preventDefault();
}, { passive: false });

// 监听路由更改，将新页面添加到访问历史
router.afterEach((to) => {
    historyManager.add(to.path);
})

// 提供历史记录给子组件
app.use(router); // 使用路由
app.use(store);  // 添加 Vuex store

app.mount('#app'); // 挂载应用