<template>
  <router-view/>
</template>

<style>
#app {
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

}

/* 默认的浅色模式样式
font-family: 'Quicksand', sans-serif;
font-family: Avenir, Helvetica, Arial, sans-serif;
body {
   background-color: #ededed;
}*/

/* 检测深色模式 */
/*@media (prefers-color-scheme: dark) {
  body {
    background-color: #111111;
    color: white;
  }
}*/
</style>

<script setup>
</script>
