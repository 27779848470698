// historyManager.js
// export const historyManager = {
//     history: [],
//     add(path) {
//         if (!this.history.includes(path)) {
//             this.history.push(path);
//         }
//     },
//     // goBack(router) {
//     //     const previousPath = this.history[this.history.length - 2];
//     //     if (previousPath) {
//     //         router.replace(previousPath)
//     //             .catch((err) => {
//     //                 console.error("Navigation error:", err.message);
//     //             });
//     //     } else {
//     //         console.log("No history available.");
//     //     }
//     // },
//     goBack(router) {
//         const lastPath = this.history[this.history.length - 1];
//         const previousPath = this.history[this.history.length - 2];
//         if (previousPath) {
//             router.replace(previousPath)
//                 .then(() => {
//                     if (this.history[this.history.length - 1] === lastPath) {
//                         this.history.pop();
//                     }
//                 })
//                 .catch((err) => {
//                     console.error("Navigation error:", err.message);
//                 });
//         } else {
//             console.log("No history available.");
//         }
//     }
// };
// export const historyManager = {
//     history: [],
//     navigating: false, // 添加标志来控制导航状态
//
//     add(path) {
//         // console.log("Adding path to history:", path);
//         // console.log("Last history:", this.history[this.history.length - 1]);
//         if (this.history[this.history.length - 1] !== path && !this.navigating) { // 检查navigating标志,避免在replace时向历史中添加路径
//             this.history.push(path);
//             // console.log("History:", this.history);
//         }
//     },
//
//     goBack(router) {
//         if (this.history.length > 1) {
//             // 设置navigating为true，以防止在replace时向历史中添加路径
//             this.navigating = true;
//
//             const previousPath = this.history[this.history.length - 2];
//             router.replace(previousPath)
//                 .then(() => {
//                     this.history.pop(); // 移除当前路径
//                     this.navigating = false; // 重置标志位
//                 })
//                 .catch((err) => {
//                     console.error("Navigation error:", err.message);
//                     this.navigating = false; // 出错也要重置标志位
//                 });
//         } else {
//             console.log("No history available.");
//         }
//     },
// };

export const historyManager = {
    // 初始化时从localStorage加载历史记录
    history: JSON.parse(localStorage.getItem('history') || '[]'),
    navigating: false,

    add(path) {
        if (this.history[this.history.length - 1] !== path && !this.navigating) {
            this.history.push(path);
            // 更新localStorage中的历史记录
            localStorage.setItem('history', JSON.stringify(this.history));
        }
    },

    goBack(router) {
        if (this.history.length > 1) {
            this.navigating = true;

            const previousPath = this.history[this.history.length - 2];
            router.replace(previousPath)
                .then(() => {
                    this.history.pop();
                    // 也更新localStorage中的历史记录
                    localStorage.setItem('history', JSON.stringify(this.history));
                    this.navigating = false;
                })
                .catch((err) => {
                    console.error("Navigation error:", err.message);
                    this.navigating = false;
                });
        } else {
            console.log("No history available.");
        }
    },

    // 新增清除历史记录的方法
    clearHistory() {
        this.history = []; // 清空内存中的历史记录
        localStorage.removeItem('history'); // 从localStorage中删除历史记录
    },
};
